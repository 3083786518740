import React, { useContext } from 'react';
import { useAsync } from 'react-async-hook';
import { Routes, Route, useParams } from 'react-router-dom';
import { Config, ConfigContext } from './common/utils/config';
import { DOI, loadMetadata, loadMetadataWithIdentifier, loadTaxonNode } from './common/utils/api';
import GeneralDOIPage from './general/GeneralDOIPage';
import ListPage from './plutof/ListPage';
import SHPage from './species/components/SHPage';
import { loadSpeciesHypothesis, loadSpeciesHypothesisWithCode } from './species/utils/api';
import THPage from './taxon/components/THpage';
import { loadTaxonHypothesis } from './taxon/utils/api';
import plutofLogo from './common/assets/plutof-logo-green-cropped.svg';
import ErrorRoute from './common/components/ErrorRoute';

export default function App() {
    const config = useContext(ConfigContext);

    return (
        <div>
            <div className="top-bar">
                <a href={config.external.plutof.landing} className="top-bar__brand" tabIndex={1}>
                    <img className="top-bar__brand__logo" src={plutofLogo}  alt="" />
                </a>

                <div className="top-bar-button-bar-wrapper">
                    <a href={config.external.plutof.app} id="login-button" className="login-form-button">
                        Open Workbench
                    </a>
                </div>
            </div>

            <Routes>
                <Route path="/" element={<ListPage />} />
                <Route path="/doi/id/:id" element={<DOIIDRoute />} />
                <Route path="/doi/*" element={<DOICodeRoute />} />
                <Route path="/species-hypothesis/:code" element={<SHRoute />} />
            </Routes>
        </div>
    )
}

function DOICodeRoute() {
    const params = useParams();
    const doi = params['*'];

    if (!doi) {
        return <ErrorRoute error={new Error('DOI missing from URL')} />
    }

    return <DOIRoute key={doi} getMetadata={config => loadMetadataWithIdentifier(config, doi)} />
}

function DOIIDRoute() {
    const { id } = useParams();

    if (!id) {
        return <ErrorRoute error={new Error('DOI id missing from URL')} />
    }

    return <DOIRoute key={id} getMetadata={config => loadMetadata(config, id)} />
}

function DOIRoute({ getMetadata }: { getMetadata: (config: Config) => Promise<DOI> }) {
    return <ConfigContext.Consumer>
        {config => {
            const promise = getMetadata(config).then(metadata => {
                if (!metadata) {
                    throw new Error('DOI not found');
                }

                return buildDOIRoute(config, metadata);
            });

            return <AsyncRoute promise={promise}>
                {result => result}
            </AsyncRoute>
        }}
    </ConfigContext.Consumer>;
}

function SHRoute() {
    const { code } = useParams();

    if (code) {
        return <ConfigContext.Consumer>
            {config => {
                return <AsyncRoute promise={loadSpeciesHypothesisWithCode(config, code)}>
                    {props => <SHPage
                        metadata={props.metadata}
                        cluster={props.cluster}
                        shData={props.speciesHypothesis}
                        taxonNode={null}
                    />}
                </AsyncRoute>
            }}
        </ConfigContext.Consumer>;
    } else {
        return <ErrorRoute error={new Error('Missing SH code')} />
    }
}

function AsyncRoute<T>({ children, promise }: {
    children: (result: T) => React.ReactNode,
    promise: Promise<T>,
}) {
    const load = useAsync<T>(() => promise, []);
    const value = load.result;

    if (value) {
        return <div>{children(value)}</div>
    } else if(load.loading) {
        // TODO: Some styling for this: spinner, consistency with page content etc
        return <div style={{padding: '1rem'}}>Loading...</div>
    } else if(load.error) {
        return <ErrorRoute error={load.error} />
    } else {
        return <div>Failed to load, but not error</div>
    }
}

async function buildDOIRoute(config: Config, doi: DOI) {
    const identifier = doi.attributes.identifier.split('/').pop() ?? '';

    if(identifier.startsWith('TH')) {
        const taxonHypothesis = await loadTaxonHypothesis(config, doi.id);
        const taxonNode = await loadTaxonNode(config, taxonHypothesis.taxon.id.toString());

        return <THPage
            metadata={doi}
            taxonHypothesis={taxonHypothesis}
            taxonNode={taxonNode}
        />;
    } else if(identifier.startsWith('SH')) {
        const { cluster, shData } = await loadSpeciesHypothesis(config, doi);
        const taxonNode = await loadTaxonNode(config, cluster.relationships.taxon_node.data.id);

        return <SHPage
            metadata={doi}
            cluster={cluster}
            shData={shData}
            taxonNode={taxonNode}
        />;
    } else {
        return <GeneralDOIPage metadata={doi} />
    }
}

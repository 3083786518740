import React, { useState } from 'react';
import { Button, LinkButton } from './controls/buttons'
import { Modal, Dialog } from './Modal';
import { ConfigContext } from '../utils/config';
import SeparatedList from './SeparatedList';
import { DOI, DOIMedia, logDownload } from '../utils/api';
import { FormField, TextInput, TextArea, Dropdown } from './controls/fields';
import classNames from './Files.module.css';

const PREDEFINED_REASONS = [
    { code: 'ecology', label: 'Ecology' },
    { code: 'monitoring', label: 'Monitoring' },
    { code: 'taxonomy', label: 'Taxonomy' },
    { code: 'agriculture', label: 'Agriculture & Food Sciences'},
    { code: 'medicine', label: 'Medicine' },
]

type Validator<Form extends {}> = (state: { [field in keyof Form]: string }) => boolean;
type ValidatorWithDependencies<Form extends {}> = { fields: Set<keyof Form>, validator: Validator<Form> };

function fieldValidator<Form extends {}>(fields: (keyof Form)[], validator: (...fields: string[]) => boolean): ValidatorWithDependencies<Form> {
    return {
        fields: new Set(fields),
        validator: state => validator(...fields.map(field => state[field])),
    };
}

function buildValidators<Form extends {}>(validators: ValidatorWithDependencies<Form>[]) {
    const validatorMap: Partial<{ [field in keyof Form]: Validator<Form>[] }> = {};
    // Field -> set of field to revalidate
    const dependencies: Partial<{ [field in keyof Form]: Set<keyof Form> }> = {};

    for(const validator of validators) {
        for(const field of validator.fields) {
            const fieldValidators = validatorMap[field] || [];
            validatorMap[field] = fieldValidators;

            fieldValidators.push(validator.validator);

            const fieldDependencies = dependencies[field] || new Set();
            dependencies[field] = fieldDependencies;

            for(const linkedField of validator.fields) {
                fieldDependencies.add(linkedField);
            }
        }
    }

    return {
        dependencies,
        validators: validatorMap,
    };
};

type FormFields = {
    name: string;
    email: string;
    organization: string;
    reasonCode: string;
    reason: string;
}

const validators = buildValidators<FormFields>([
    fieldValidator(['name'], value => value.length > 0),
    fieldValidator(['email'], value => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)),
    fieldValidator(['organization'], value => value.length > 0),
    fieldValidator(
        ['reason', 'reasonCode'],
        (reason, code) => code !== '' && (code !== 'other' || reason.length > 0)
    ),
]);

export default function Files({ metadata }: { metadata: DOI }) {
    const isUniteDOI = metadata.attributes.owner_name === 'UNITE database';

    const [modalOpen, toggleModal] = useState(false);
    const [tosAccepted, toggleToS] = useState(false);
    const [fileBeingDownloaded, updateFileBeingDownloaded] = useState<DOIMedia | null>(null);
    const [highlightValidationErrors, updateHightlightValidationErrors] = useState(false);

    const [formState, updateFormState] = useState<FormFields>({
        name: '',
        email: '',
        organization: '',
        reasonCode: '',
        reason: '',
    });

    const [validations, updateValidations] = useState({
        valid: false,
        fields: {
            name: false,
            email: false,
            organization: false,
            reasonCode: false,
            reason: false,
        },
    });

    function update(field: keyof typeof formState) {
        return function(value: string) {
            formState[field] = value;
            updateFormState(Object.assign({}, formState));

            for(const dirtyField of (validators.dependencies[field] || [])) {
                validations.fields[dirtyField] = (validators.validators[dirtyField] || []).every(validator => validator(formState));
            }

            validations.valid = Object.values(validations.fields).every(valid => valid);

            updateValidations(Object.assign({}, validations));
        };
    }

    return <ConfigContext.Consumer>
        {config => {
            function logAndDownload() {
                if(validations.valid && fileBeingDownloaded) {
                    logDownload(config, metadata.attributes.doi, fileBeingDownloaded.name, {
                        name: formState.name,
                        email: formState.email,
                        organization: formState.organization,
                        reason: formState.reasonCode === 'other' ?
                            formState.reason :
                            (PREDEFINED_REASONS.find(reason => reason.code === formState.reasonCode)?.label ?? ''),
                    });

                    window.open(fileBeingDownloaded.url, '_blank');
                    toggleModal(false)
                } else {
                    updateHightlightValidationErrors(true);
                }
            }

            function openModal(file: DOIMedia) {
                updateFileBeingDownloaded(file);
                toggleModal(true);
            }

            // ToS is not part of the validations, so the weird "don't highlight until the first press"
            // logic doesn't apply to it
            const footer = <Button onClick={logAndDownload} disabled={(highlightValidationErrors && !validations.valid) || !tosAccepted}>
                Download
            </Button>

            return <>
                <SeparatedList>
                    {metadata.attributes.media.map((file, index) => 
                        isUniteDOI ?
                            <LinkButton
                                key={index}
                                onClick={() => openModal(file)}
                            >
                                {file.name}
                            </LinkButton>
                            :
                            <a
                                key={index}
                                href={file.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {file.name}
                            </a>
                    )}
                </SeparatedList>

                {modalOpen && <Modal close={() => toggleModal(false)}>
                    <Dialog
                        header="Download"
                        footer={footer}
                    >
                        We are planning to launch new features/datasets and would like to get to
                        know our users to provide you a better experience.

                        <FormField label="Name">
                            <TextInput
                                value={formState.name}
                                onChange={update('name')}
                                invalid={highlightValidationErrors && !validations.fields.name}
                                autoComplete="name" />
                        </FormField>

                        <FormField label="Email">
                            <TextInput
                                value={formState.email}
                                onChange={update('email')}
                                invalid={highlightValidationErrors && !validations.fields.email}
                                autoComplete="email" />
                        </FormField>

                        <FormField label="Organization">
                            <TextInput
                                value={formState.organization}
                                onChange={update('organization')}
                                invalid={highlightValidationErrors && !validations.fields.organization} />
                        </FormField>

                        <FormField label="Reason">
                            <Dropdown
                                value={formState.reasonCode}
                                onChange={update('reasonCode')}
                                invalid={highlightValidationErrors && !validations.fields.reasonCode}
                                className={classNames.reasonDropdown}
                            >
                                <option value="">---</option>
                                <optgroup label="Research">
                                    {PREDEFINED_REASONS.map(reason => (
                                        <option key={reason.code} value={reason.code}>
                                            {reason.label}
                                        </option>
                                    ))}
                                </optgroup>

                                <option value="other">Other</option>
                            </Dropdown>

                            {formState.reasonCode === 'other' && <TextArea
                                value={formState.reason}
                                onChange={update('reason')}
                                invalid={highlightValidationErrors && !validations.fields.reason} />}
                        </FormField>

                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={tosAccepted}
                                    className={classNames.tosCheckbox}
                                    onChange={event => {
                                        toggleToS(event.target.checked);
                                    }} />

                                I agree to the terms and conditions and to receive emails from UNITE.
                                Don't worry, we also don’t like spam.

                                <a href="https://unite.ut.ee/terms.php" target="_blank" rel="noopener noreferrer">
                                    Read more from here
                                </a>
                            </label>
                        </div>
                    </Dialog>
                </Modal>}
            </>;
        }}
    </ConfigContext.Consumer>
};

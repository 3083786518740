import React, { useState } from 'react';
import BlankLink from '../../common/components/BlankLink';
import { LinkButton } from '../../common/components/controls/buttons';
import { Modal } from '../../common/components/Modal';
import { PanelRaw } from '../../common/components/Panel';
import { GalleryModal } from './GalleryModal';
import galleryStyle from './Gallery.module.css';
import { SHDataSequenceImage } from '../utils/api';

export function VerticalGallery({ files }: { files: SHDataSequenceImage[] }) {
    return (
        <PanelRaw>
            <div className={`${galleryStyle.panel} ${galleryStyle.verticalGallery}`}>
                {files.map(file => (
                    <div key={file.id} className={galleryStyle.verticalItem}>
                        <BlankLink href={file.download_links.large}>
                            <img src={file.download_links.small} className={galleryStyle.thumbnail} alt="Gallery item" />
                        </BlankLink>
                    </div>
                ))}
            </div>
        </PanelRaw>
    );
}

export default function Gallery({ files }: { files: SHDataSequenceImage[] }) {
    const [modalOpen, toggleModal] = useState(false);
    const [modalFile, updateModalFile] = useState<SHDataSequenceImage | null>(null);

    function openModal(file: SHDataSequenceImage) {
        updateModalFile(file);
        toggleModal(true);
    }

    return <>
        <PanelRaw>
            <div className={`${galleryStyle.panel} ${galleryStyle.gallery}`}>
                {files.map(file => (
                    <div key={file.id} className={galleryStyle.item}>
                        <LinkButton onClick={() => openModal(file)}>
                            <img src={file.download_links.small} className={galleryStyle.thumbnail} alt="Gallery item" />
                        </LinkButton>
                    </div>
                ))}
            </div>
        </PanelRaw>

        {modalOpen && <Modal close={() => toggleModal(false)}>
            {modalFile && <GalleryModal
                toggleModal={toggleModal}
                updateModalFile={updateModalFile}
                file={modalFile}
                files={files} />}
        </Modal>}
    </>;
};

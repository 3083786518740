import { SHDataSequence } from './api';

export const SEQUENCE_STATUSES: { field: keyof SHDataSequence, name: string, color: string }[] = [
    {
        field: 'is_chimeric',
        name: 'chimeric',
        color: '#ff0000',
    },

    {
        field: 'low_quality',
        name: 'low quality',
        color: '#800000',
    },

    {
        field: 'is_excluded',
        name: 'EX = sequence to be excluded from the next version of global key',
        color: '#323029',
    },
];

export function hasStatus(sequence: SHDataSequence) {
    return SEQUENCE_STATUSES.some(status => sequence[status.field]);
}

import React from 'react';
import classNames from './Header.module.css';
import pageClassNames from '../styles/page.module.css';
import uniteLogo from '../assets/unite-logo.svg';
import plutofLogo from '../assets/PlutoF_Data_Hosted_by_Black.svg';

type HeaderProps = {
    name: string;
    source: string;
};

export default function Header({ name, source }: HeaderProps) {
    return (
        <div className={`${pageClassNames.centerContent} ${classNames.header}`}>
            <div className={classNames.name}>
                {name}
            </div>

            <div className={classNames.source}>
                {source}
            </div>

            <div className={classNames.logos}>
                <a href='https://unite.ut.ee'>
                    <img src={uniteLogo} className={classNames.uniteLogo} alt="UNITE logo" />
                </a>

                <div className={classNames.logoSeparator}></div>

                <a href='https://plutof.ut.ee'>
                    <img src={plutofLogo} className={classNames.plutofLogo} alt="PlutoF logo" />
                </a>
            </div>
        </div>
    );
}

import React, { useCallback, useContext, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { ConfigContext } from '../common/utils/config';
import { DOI, PublicAPIList, searchDOIs } from '../common/utils/api';
import Navbar from './Navbar';
import Pagination from './Pagination';
import Search from './Search';
import classNames from './ListPage.module.css';
import listClasses from './list.module.css';
import pageClasses from '../common/styles/page.module.css';

export default function ListPage(props: {}) {
    const config = useContext(ConfigContext);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const search = useCallback(
        () => searchDOIs(config, searchQuery, { page: currentPage }),
        [config, searchQuery, currentPage],
    );

    // TODO: Figure out why we need to pass deps here. useCallback updating
    // should be enough
    const load = useAsync<PublicAPIList<DOI>>(search, [searchQuery, currentPage], {
        setLoading: state => ({ ...state, loading: true }),
    });

    if (load.error) {
        let message = load.error.toString();

        if (load.error instanceof z.ZodError) {
            message = JSON.stringify(load.error.format(), null, 4);
        }

        console.error(load.error);

        return <div>{message}</div>;
    }

    return (
        <div className={classNames.page}>
            <div className={classNames.navbar}>
                <Navbar
                    search={(
                        <Search
                            searching={load.loading}
                            search={setSearchQuery} />
                    )}

                    pagination={(
                        <Pagination
                            page={currentPage}
                            totalPages={load.result?.meta.pagination.pages ?? 1}
                            loading={load.loading}
                            switchPage={setCurrentPage} />
                    )}
                />
            </div>

            <div className={pageClasses.centerContent}>
                <div className={listClasses.panel}>
                    <table className={listClasses.table}>
                        <thead>
                            <tr>
                                <th className={listClasses.header}>
                                    Dataset
                                </th>

                                <th className={listClasses.header}>
                                    DOI
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {(load.result?.data || []).map(doi => (
                                <tr key={doi.id}>
                                    <td className={`${listClasses.cell} ${listClasses.cellDataset}`}>
                                        <Link to={`/doi/${doi.attributes.identifier}`} title={doi.attributes.identifier}>
                                            {doi.attributes.citation}
                                        </Link>
                                    </td>

                                    <td className={listClasses.cell}>
                                        {doi.attributes.identifier}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import classNames from './buttons.module.css';

type ButtonProps = {
    onClick: () => void;
    disabled?: boolean;
    title?: string;
    children: React.ReactNode;
}

export function Button(props: ButtonProps) {
    return (
        <button
            onClick={props.onClick}
            disabled={props.disabled}
            title={props.title}
            className={classNames.button}
        >
            {props.children}
        </button>
    )
}

// TODO: Forgot about this; replace all the controls.linkButton classes
// with it
export function LinkButton(props: ButtonProps) {
    return <button
        onClick={props.onClick}
        disabled={props.disabled}
        title={props.title}
        className={classNames.linkButton}
    >
        {props.children}
    </button>
}

import React from 'react';
import panelClasses from '../../common/styles/overview-panel.module.css';

interface Props {
    clusters: number;
    itsSequences: number;
}

export default function Statistics(props: Props) {
    return (
        <div className={panelClasses.panel}>
            <header className={panelClasses.header}>
                Statistics
            </header>

            <table className={panelClasses.table}>
                <tbody>
                    <tr>
                        <td className={panelClasses.tableCell}>
                            <label className={panelClasses.label}>
                                Number of SHs
                            </label>
                        </td>

                        <td className={panelClasses.tableCell}>
                            {props.clusters}
                        </td>
                    </tr>

                    <tr>
                        <td className={panelClasses.tableCell}>
                            <label className={panelClasses.label}>
                                Number of ITS sequences
                            </label>
                        </td>

                        <td className={panelClasses.tableCell}>
                            {props.itsSequences}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

import React from 'react';
import SeparatedList from '../../common/components/SeparatedList';
import { ConfigContext } from '../../common/utils/config';
import { SHDataSequence, SequenceAccesionNumber } from '../utils/api';

interface Props {
    sequenceID: number;
    accnos: SequenceAccesionNumber[];
}

export default function AccessionNumbers({ sequenceID, accnos }: Props) {
    return (
        <ConfigContext.Consumer>
            {config => {
                const sequenceView = config.external.plutof.views.sequence(sequenceID);

                function props(accno: SHDataSequence['accnos'][0]) {
                    switch(accno.database) {
                        case 'INSD':
                            return {
                                href: `https://www.ncbi.nlm.nih.gov/nuccore/${accno.accno}`,
                                // style: { color: '#CC6633' },
                            };
                        case 'UNITE':
                            return {
                                href: `https://unite.ut.ee/bl_forw.php?id=${sequenceID}`,
                                // style: { color: '#FDC81A' },
                            };
                        default:
                            return {
                                href: sequenceView,
                            };
                    }
                }

                return (
                    <SeparatedList separator=' | '>
                        {accnos.map(accno => (
                            <a key={accno.accno} {...props(accno)} rel="noopener">
                                {accno.accno}
                            </a>
                        ))}
                    </SeparatedList>
                );
            }}
        </ConfigContext.Consumer>
    );
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFastBackward } from '@fortawesome/free-solid-svg-icons/faFastBackward';
import { faStepBackward } from '@fortawesome/free-solid-svg-icons/faStepBackward';
import { faFastForward } from '@fortawesome/free-solid-svg-icons/faFastForward';
import { faStepForward } from '@fortawesome/free-solid-svg-icons/faStepForward';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import classNames from 'classnames';
import paginationClassNames from './Pagination.module.css';
import navbarClassNames from './Navbar.module.css';

interface Props {
    page: number;
    totalPages: number;
    loading: boolean;
    switchPage: (page: number) => void;
    disabled?: boolean;
}

export default function Pagination({ page, totalPages, loading, switchPage, disabled = false }: Props) {
    const onFirst = page === 1;
    const onLast = page === totalPages;

    const buttonClass = classNames(navbarClassNames.navbarButton, paginationClassNames.button);

    return (
        <div className={paginationClassNames.pagination}>
            <button onClick={() => switchPage(1)} className={buttonClass} disabled={disabled || onFirst}>
                <FontAwesomeIcon icon={faFastBackward} />
            </button>

            <button onClick={() => switchPage(page - 1)} className={buttonClass} disabled={disabled || onFirst}>
                <FontAwesomeIcon icon={faStepBackward} />
            </button>

            <button onClick={() => switchPage(page)} className={buttonClass} disabled={disabled}>
                {page}

                <span className={paginationClassNames.separator}>
                    {loading ? (<FontAwesomeIcon icon={faSync} spin />) : '/'}
                </span>

                {totalPages}
            </button>

            <button onClick={() => switchPage(page + 1)} className={buttonClass} disabled={disabled || onLast}>
                <FontAwesomeIcon icon={faStepForward} />
            </button>

            <button onClick={() => switchPage(totalPages)} className={buttonClass} disabled={disabled || onLast}>
                <FontAwesomeIcon icon={faFastForward} />
            </button>
        </div>
    );
};

import React from 'react';
import classNames from './Distribution.module.css';
import { PanelRaw } from './Panel';

export default function Distribution({ map }: { map: React.ReactNode }) {
    return <PanelRaw>
        <div className={classNames.map}>
            {map}
        </div>

        <p className={classNames.centroidNotice}>
            * Locations without exact coordinates are displayed as country centroids
        </p>
    </PanelRaw>;
};

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from './PreviousVersions.module.css';
import { SHCluster } from '../utils/api';

interface Props {
    versions: SHCluster['attributes']['previous_versions'];
}

export default function PreviousSHVersions({ versions }: Props) {
    const header = versions.length > 1 ?
        'Older versions of this SH are available' :
        'Older version of this SH is available';

    const sortedVersions = Array.from(versions);
    sortedVersions.sort((a, b) => b.id - a.id);

    return <>
        <p className={classNames.header}>
            {header}
        </p>

        <table className={classNames.table}>
            <thead>
                <tr>
                    <th className={classNames.header}>SH code</th>
                    <th className={classNames.header}>Count*</th>
                    <th className={classNames.header}>Total count**</th>
                </tr>
            </thead>

            <tbody>
                {sortedVersions.map(version => {
                    return <tr key={version.id}>
                        <td className={classNames.cell}>
                            <Link to={`/species-hypothesis/${version.code}`}>
                                {version.code}
                            </Link>
                        </td>

                        <td className={classNames.cell}>
                            {version.sequences_carried_over}
                        </td>

                        <td className={classNames.cell}>
                            {version.sequences_total}
                        </td>
                    </tr>
                })}
            </tbody>
        </table>

        <div>
            * Number of sequences carried over from previous version <br />
            ** Total number of sequences composing this SH in current version
        </div>
    </>
}
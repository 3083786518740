import React, { useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faTint } from '@fortawesome/free-solid-svg-icons/faTint';
import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons/faCompressArrowsAlt';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons/faExpandArrowsAlt';
import { TabGroup } from '../../common/components/TabGroup';
import { Config, ConfigContext } from '../../common/utils/config';
import { LinkButton } from '../../common/components/controls/buttons';
import { SEQUENCE_STATUSES, hasStatus } from '../utils/sequence-status';
import StatusLegend from './StatusLegend';
import './alignment-renderer/index.js';
import './alignment-renderer/index.css';
import classNames from './GraphicalView.module.css';
import { SHDataSequence } from '../utils/api';

const COLLAPSE_LEVELS = [
    { name: 'Level 1', description: 'Sequences within the same 1.0% SH for the same continent collapsed' },
    { name: 'Level 2', description: 'Sequences within the same 1.0% SH for the same country collapsed' },
    { name: 'Level 3', description: 'Sequences within the same 0.5% SH for the same continent collapsed' },
    { name: 'Level 4', description: 'Sequences within the same 0.5% SH for the same country collapsed' },
    { name: 'Level 5', description: 'Identical sequences for the same continent collapsed' },
    { name: 'Level 6', description: 'Identical sequences for the same country collapsed' },
    { name: 'Level 7', description: 'All sequences belonging to the SH are shown' },
];

// TODO: How to make alignment renderer properly importable (without publishing it)

// Alignment renderer types (incomplete, but enough to be useful)
interface AlignmentSequence {
    id: number;
    sequence: string;

    clusterLinks: {
        id: string;
        name: string;
        color: string;
        url?: string | null;
    }[];
}

interface AlignmentField {};
interface AlignmentConfigFieldBuilders<S extends AlignmentSequence> {
    element: (name: string, content: (seq: S) => HTMLElement) => AlignmentField;
    text: (name: string, value: (seq: S) => string) => AlignmentField;
}

interface AlignmentRenderer {
    destroy: () => void;
}

declare global {
    interface Window {
        AlignmentRenderer: {
            render: <S extends AlignmentSequence>(
                mountPoint: HTMLElement,
                sequences: S[],
                options: (generator: any) => any
            ) => AlignmentRenderer;

            expectedHeight: any;
        }
    }
}

interface PreparedSequence extends AlignmentSequence{
    fullSequence: SHDataSequence;
    collapse: {
        level: number;
        parentID: string | null;
        parent: PreparedSequence | null;
        descendants: PreparedSequence[];
    }
}

function alignmentConfig(appConfig: Config, shCode: string, collapseLevel: number) {
    return (fields: AlignmentConfigFieldBuilders<PreparedSequence>) => {
        return {
            thresholds: ['0.5', '1.0', '1.5', '2.0', '2.5', '3.0'],
            disabledThresholds: [],
            activeCluster: shCode,
            stickyColumns: 2,

            fields: [
                fields.element('', seq => {
                    const container = document.createElement('span');

                    for(const status of SEQUENCE_STATUSES) {
                        if(seq.fullSequence[status.field]) {
                            const statusSpan = document.createElement('span');
                            // ¯\_(ツ)_/¯
                            ReactDOM.render(<FontAwesomeIcon icon={faTint} color={status.color} />, statusSpan);

                            container.appendChild(statusSpan);
                        }
                    }

                    return container;
                }),

                fields.element('Sequence ID', seq => {
                    const container = document.createElement('span');

                    for(const accessionNumber of seq.fullSequence.accnos) {
                        const element = document.createElement('a');

                        switch(accessionNumber.database) {
                            case 'INSD': {
                                element.href = `https://www.ncbi.nlm.nih.gov/nuccore/${accessionNumber.accno}`;
                                // element.style.color = '#CC6633';
                                break;
                            }

                            case 'UNITE': {
                                element.href = `https://unite.ut.ee/bl_forw.php?id=${seq.id}`;
                                // element.style.color = '#FDC81A';
                                break;
                            }

                            default: {
                                element.href = appConfig.external.plutof.views.sequence(seq.id);
                                break;
                            }
                        }

                        element.innerText = accessionNumber.accno;

                        container.appendChild(element);
                    }

                    return container;
                }),

                fields.element('', seq => {
                    const span = document.createElement('span');

                    // If a descendant is already visible on this level, we must not count its own descendants
                    // (cutting tail off after breakPoint works because seq.collapse.descendants are ordered by level)
                    const breakPoint = seq.collapse.descendants.findIndex(s => s.collapse.level <= collapseLevel);
                    const descendants = breakPoint === -1 ? seq.collapse.descendants : seq.collapse.descendants.slice(0, breakPoint);

                    if (descendants.length > 0) {
                        span.innerHTML = `+${descendants.length}`;
                    }

                    return span;
                }),

                fields.element('UNITE taxon name', seq => {
                    const container = document.createElement('span');

                    const taxon = document.createElement('span');
                    taxon.innerText = seq.fullSequence.unite_taxon_name ?? '';

                    container.appendChild(taxon);

                    if (seq.fullSequence.type) {
                        const type = document.createElement('type');
                        type.innerText = seq.fullSequence.type;
                        type.classList.add(classNames.sourceType)

                        container.appendChild(type);
                    }

                    return container;
                }),

                fields.text('INSD taxon name', seq => seq.fullSequence.insd_taxon_name ?? ''),
                fields.text('Source', seq => seq.fullSequence.dna_source),
                fields.text('Interacting taxa', seq => (seq.fullSequence.interacting_taxa ?? []).map(t => t.taxon_name).join(', ')),
                fields.text('Area', seq => seq.fullSequence.country_name),
            ],

            nucleotides: {
                width: 12,

                palette: {
                    'A': ['white', '#fb0018'],
                    'G': ['black', '#fffd33'],
                    'C': ['black', '#38fd2a'],
                    'T': ['white', '#0022fb'],
                    'default': ['black', '#dddddd']
                },
            },

            guideline: {
                step: 10,
            },
        };
    };
}

interface RenderedSequencesProps<S extends {}> {
    sequences: S[];
    config: Config;
    shCode: string;
    collapseLevel: number;
}

function RenderedSequences<S extends AlignmentSequence>(props: RenderedSequencesProps<S>) {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            const height = window.AlignmentRenderer.expectedHeight(props.sequences) + 12 * 3;
            container.style.height = `min(${height}px, 90vh)`;

            // TODO: Alignment renderer expects some fields on sequences, I think
            const render = window.AlignmentRenderer.render(
                container,
                props.sequences,
                alignmentConfig(props.config, props.shCode, props.collapseLevel),
            );

            return () => render.destroy();
        }
    });

    return <div ref={containerRef}></div>
}

function prepareSequences(sequences: SHDataSequence[]): PreparedSequence[]  {
    // TODO: Deal away with this, pass something like { sequence, collapse } instead
    const prepared: PreparedSequence[] = sequences.map(seq => ({
        id: seq.id,
        sequence: seq.its_sequence,

        clusterLinks: seq.sh_colours.map(link => ({
            id: link.sh_code,
            name: link.sh_code,
            color: link.hex_colour,
        })),

        fullSequence: seq,

        collapse: {
            level: seq.collapse_level || 0,
            parentID: (!seq.collapse_parent_sequence_id || seq.collapse_parent_sequence_id === 0) ? null : seq.collapse_parent_sequence_id.toString(),

            // Filled below
            parent: null,
            descendants: [],
        },
    }));

    // Gather collapse descendants from child->parent links
    const byID: Record<string, PreparedSequence> = {};

    for(const seq of prepared) {
        byID[seq.fullSequence.id] = seq;
    }

    // Start from leaves and gather descendants upwards
    const ordered = Array.from(prepared);
    ordered.sort((a, b) => b.collapse.level - a.collapse.level);

    for(const seq of ordered) {
        if(seq.collapse.parentID) {
            const parent = byID[seq.collapse.parentID];

            parent.collapse.descendants.push(seq);
            parent.collapse.descendants = parent.collapse.descendants.concat(seq.collapse.descendants);

            seq.collapse.parent = parent;
        }
    }

    return prepared;
}

interface GraphicalViewProps {
    sequences: SHDataSequence[],
    shCode: string,
    isWide: boolean;
    toggleWidth: (isWide: boolean) => void;
}

export default function GraphicalView({ sequences, shCode, toggleWidth, isWide }: GraphicalViewProps) {
    const config = useContext(ConfigContext);

    const preparedSequences = prepareSequences(sequences);

    const tabs = COLLAPSE_LEVELS.map((meta, levelIndex) => {
        const levelSequences = preparedSequences.filter(seq => seq.collapse.level <= levelIndex);

        return {
            name: `${meta.name} (${levelSequences.length})`,
            lazy: true,
            content: (<div>
                <div className={classNames.levelHeader}>
                    <div>
                        {meta.description}
                    </div>

                    <div className={classNames.headerControls}>
                        <LinkButton
                            onClick={() => toggleWidth(!isWide)}
                            title="Toggle alignment panel's width"
                        >
                            <FontAwesomeIcon icon={isWide ? faCompressArrowsAlt : faExpandArrowsAlt} size="1x" />
                        </LinkButton>

                        <LinkButton onClick={() => {}} disabled={true}>
                            <FontAwesomeIcon icon={faCog} size="1x" />
                        </LinkButton>
                    </div>
                </div>

                <RenderedSequences
                    sequences={levelSequences}
                    collapseLevel={levelIndex}
                    shCode={shCode}
                    config={config} />

                {levelSequences.some(seq => hasStatus(seq.fullSequence)) && <StatusLegend />}
            </div>)
        }
    });

    return <TabGroup tabs={tabs} />;
}

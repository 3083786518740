import React from 'react';
import { Panel, PanelField } from '../../common/components/Panel';
import SeparatedList from '../../common/components/SeparatedList';
import { TaxonHypothesis } from '../utils/api';

interface Props {
    ecmLineages: TaxonHypothesis['ecm_lineages'];
    interactingTaxa: TaxonHypothesis['interacting_taxa'];
}

export default function Ecology({ ecmLineages, interactingTaxa }: Props) {
    return (
        <Panel header='Ecology'>
            <PanelField label='Interacting taxa'>
                <SeparatedList separator='; '>
                    {interactingTaxa.map(taxon => `${taxon.taxon_name} (${taxon.count})`)}
                </SeparatedList>
            </PanelField>

            <PanelField label='EcM lineage'>
                <SeparatedList separator='; '>
                    {ecmLineages.map(lineage => `${lineage.ecm_lineage} (${lineage.count})`)}
                </SeparatedList>
            </PanelField>
        </Panel>
    );
};

import React from 'react';
import classNames from './Panel.module.css';

export function PanelSection({ children }: { children: React.ReactNode }) {
    return <tr className={classNames.tableSection}>
        <td>
            {children}
        </td>
    </tr>
}

export function PanelField({ label, children }: { label: React.ReactNode, children: React.ReactNode }) {
    return <tr className={classNames.tableSection}>
        <td>
            <label className={classNames.label}>
                {label}
            </label>
        </td>

        <td>
            {children}
        </td>
    </tr>
}

export function PanelContent({ children }: { children: React.ReactNode }) {
    return <table className={classNames.tableContent}>
        <tbody>
            {children}
        </tbody>
    </table>
}

export function Panel({ header, children }: { header?: React.ReactNode, children: React.ReactNode}) {
    return <div className={classNames.panel}>
        {header && <header className={classNames.header}>
            {header}
        </header>}

        <div className={classNames.contentContainer}>
            <PanelContent>
                {children}
            </PanelContent>
        </div>
    </div>
}

export function PanelRaw({ header, children }: { header?: React.ReactNode, children: React.ReactNode }) {
    return <div className={classNames.panel}>
        {header && <header className={classNames.header}>
            {header}
        </header>}

        <div className={classNames.contentContainer}>
            {children}
        </div>
    </div>
}
import React from 'react';
import { Panel, PanelField } from '../../common/components/Panel';
import AccessionNumbers from './AccessionNumbers';
import { SHDataReferenceSequence } from '../utils/api';

// Literally the same, as EE local time, except for, apparently, an extra comma
const formatDatetime = (function() {
    function pad(component: number) {
        return component.toString().padStart(2, '0');
    }

    return function(dt: Date) {
        const day = pad(dt.getDate());
        const month = pad(dt.getMonth() + 1);
        const hh = pad(dt.getHours());
        const mm = pad(dt.getMinutes());
        const ss = pad(dt.getSeconds());

        return `${day}/${month}/${dt.getFullYear()} ${hh}:${mm}:${ss}`;
    }
})();

interface Props {
    sequence: SHDataReferenceSequence;
    header: string;
}

export default function RepresentativeSequence({ sequence, header }: Props) {
    const timestamp = new Date(sequence.set_time);

    return (
        <Panel header={header}>
            <PanelField label={sequence.accnos.length === 1 ? 'Accession number' : 'Accession numbers'}>
                <AccessionNumbers sequenceID={sequence.id} accnos={sequence.accnos} />
            </PanelField>

            <PanelField label='Chosen by'>
                {sequence.set_by}, {formatDatetime(timestamp)}
            </PanelField>
        </Panel>
    );
};

import React from 'react';
import classnames from 'classnames';
import tabClasses from './TabGroup.module.css';

type TabGroupProps = {
    tabs: {
        name: string,
        lazy?: boolean,
        content: React.ReactNode,
    }[],
}

type TabGroupState = {
    currentTab: string | undefined;
}

export class TabGroup extends React.Component<TabGroupProps, TabGroupState> {
    constructor(props: TabGroupProps) {
        super(props);

        this.state = { currentTab: props.tabs[0]?.name };
    }

    isTabActive(name: string) {
        return name === this.state.currentTab;
    }

    switchTab(name: string) {
        this.setState({ currentTab: name });
    }

    render() {
        const tabs = this.props.tabs;

        return (
            <div className={tabClasses.group}>
                <nav className={tabClasses.selector}>
                    {tabs.map(tab => {
                        const classes = classnames(tabClasses.nav, {
                            [tabClasses.navActive]: this.isTabActive(tab.name),
                        });

                        return (
                            <div onClick={() => this.switchTab(tab.name)} className={classes} key={tab.name}>
                                {tab.name}
                            </div>
                        );
                    })}
                </nav>

                <div className={tabClasses.content}>
                    {tabs.map(tab => {
                        const active = this.isTabActive(tab.name);

                        const classes = classnames(tabClasses.tabContent, {
                            [tabClasses.tabContentActive]: active,
                        });

                        return (
                            <div className={classes} key={tab.name}>
                                {(active || !tab.lazy) && tab.content}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

import React from 'react';
import Header from './Header';
import Metadata from './Metadata';
import Section from './Section';
import DetailsSection from './DetailsSection';
import Distribution from './Distribution';
import classNames from './HypothesisPage.module.css';
import pageClassNames from '../styles/page.module.css';
import { DOI } from '../utils/api';

type Props = {
    metadata: DOI;
    header: string;
    headerSource: string;
    columnLeft?: React.ReactNode;
    columnRight?: React.ReactNode;
    map: React.ReactNode;
    gallery?: React.ReactNode,
    footer?: React.ReactNode,
    children?: React.ReactNode;
};

export default function HypothesisPage(props: Props) {
    return (
        <div className={classNames.page}>
            <Header
                name={props.header}
                source={props.headerSource} />

            <section className={`${pageClassNames.centerContent} ${classNames.overview}`}>
                <div className={classNames.overviewColumn}>
                    {props.columnLeft}
                </div>

                <div className={classNames.overviewColumn}>
                    <Distribution map={props.map} />

                    {props.metadata && <Metadata metadata={props.metadata} />}

                    {props.columnRight}
                </div>
            </section>

            {props.children}

            {props.gallery && (
                <Section header='Gallery'>
                    {props.gallery}
                </Section>
            )}

            <Section header='Detailed information'>
                <DetailsSection metadata={props.metadata} />
            </Section>

            {props.footer}
        </div>
    );
};

HypothesisPage.defaultProps = {
    tabs: [],
};

import React from 'react';
import Map from './Map';
import { SHData } from '../../species/utils/api';
import { TaxonHypothesis } from '../../taxon/utils/api';

interface Props {
    // It's kind weird, but they're mostly the same, so we basically take
    // an intersection
    distribution: SHData['distribution'] | TaxonHypothesis['distribution'],
}

export default function DistributionMap(props: Props) {
    let markers: { lat: number, lng: number }[] = [];

    let countries: {
        code: string

        coords: {
            lat: number,
            lng: number,
        }
    }[] = []

    const addedCountries = new Set<String>(); 

    props.distribution.forEach(location => {
        if(location.lat !== undefined && location.lng !== undefined) {
            // Precise location
            markers.push({
                lat: location.lat,
                lng: location.lng,
            });
        } else {
            // Only country
            if(!addedCountries.has(location.country) && location.country_lat && location.country_lng) {
                countries.push({
                    code: location.country,

                    coords: {
                        lat: location.country_lat,
                        lng: location.country_lng
                    },
                });

                addedCountries.add(location.country);
            }
        }
    });

    return (
        <Map markers={markers} countries={countries} />
    );
};

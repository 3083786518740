import React from 'react';
import { z } from 'zod';

export default function ErrorRoute<T extends Error>({ error }: { error: T }) {
    let message = error.toString();

    if (error instanceof z.ZodError) {
        message = JSON.stringify(error.format(), null, 4);
    }

    console.error(error);

    return <div>{message}</div>;
}
import React from 'react';
import { ConfigContext } from '../../common/utils/config';
import { Panel, PanelSection } from '../../common/components/Panel';
import ExternalLinks from '../../common/components/ExternalLinks';
import SeparatedList from '../../common/components/SeparatedList';
import { TaxonNode } from '../../common/utils/api';
import classNames from './Taxonomy.module.css';
import { SHCluster, SHLineageTh } from '../utils/api';

interface Props {
    lineage: SHCluster['attributes']['taxonomy']['lineage'];
    taxonNode: TaxonNode | null;
}

interface TH {
    th: SHLineageTh;
}

function ThLink({ th }: TH) {
    if(th.doi) {
         return <span> (<a href={th.doi}>{th.code}</a>)</span>
    }

    return <span> ({th.code})</span>;
}

export default function Taxonomy({ lineage, taxonNode }: Props) {
    return <div>
        <Panel header='Placement in classification and Taxon Hypotheses'>
            <PanelSection>
                <ConfigContext.Consumer>
                    {config => {
                        return <SeparatedList separator=' / '>
                            {lineage.map(taxon => <span key={taxon.taxon.id}>
                                <span className={classNames.taxonName}>
                                    {taxon.taxon.name}
                                </span>

                                {taxon.th ? <ThLink th={taxon.th} /> : ''}
                            </span>)}
                        </SeparatedList>
                    }}
                </ConfigContext.Consumer>
            </PanelSection>

            <PanelSection>
                {taxonNode ? ExternalLinks(taxonNode) : '' }
            </PanelSection>
        </Panel>
    </div>;
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import classNames from 'classnames';
import BlankLink from '../../common/components/BlankLink';
import { Dialog } from '../../common/components/Modal';
import controls from '../../common/styles/controls.module.css';
import modalClasses from './GalleryModal.module.css';
import { SHDataSequenceImage } from '../utils/api';

interface Props {
    files: SHDataSequenceImage[];
    file: SHDataSequenceImage;
    toggleModal: (open: boolean) => void;
    updateModalFile: (file: SHDataSequenceImage) => void;
}

export function GalleryModal({ file, files, toggleModal, updateModalFile }: Props) {
    function header(file: SHDataSequenceImage) {
        return <>
            <div className={modalClasses.header}>
                {file.title}

                <div className={modalClasses.headerControls}>
                    <div className={modalClasses.headerControl}>
                        <BlankLink
                            href={file.download_links.large}
                        >
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </BlankLink>
                    </div>

                    <button
                        className={classNames(controls.buttonReset, controls.linkButton, modalClasses.closeButton)}
                        onClick={() => toggleModal(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            </div>
        </>;
    }

    function footer(file: SHDataSequenceImage) {
        return <>
            <table className={modalClasses.table}>
                <tbody>
                    {file.author && <tr>
                        <td><strong>Author</strong></td>
                        <td>{file.author}</td>
                    </tr>}

                    {file.rights_owner && <tr>
                        <td><strong>Rights owner</strong></td>
                        <td>{file.rights_owner}</td>
                    </tr>}

                    <tr>
                        <td><strong>License</strong></td>
                        <td>{file.license}</td>
                    </tr>
                </tbody>
            </table>
        </>;
    }

    function loadNextFile(file: SHDataSequenceImage) {
        const nextIndex = files.indexOf(file) + 1;
        const nextFile = nextIndex < files.length ? files[nextIndex] : files[0];

        updateModalFile(nextFile);
    }

    function loadPrevFile(file: SHDataSequenceImage) {
        const nextIndex = files.indexOf(file) - 1;
        const nextFile = nextIndex >= 0 ? files[nextIndex] : files[files.length - 1];

        updateModalFile(nextFile);
    }

    return <>
        <Dialog
            header={header(file)}
            footer={footer(file)}
        >
            <img
                src={file.download_links.large}
                className={modalClasses.image}
                alt="File visual" />

            <button
                className={classNames(controls.buttonReset, modalClasses.modalNavBtn, modalClasses.navBtnPrev)}
                onClick={() => loadPrevFile(file)}
            >
                <FontAwesomeIcon icon={faArrowLeft} />
            </button>

            <button
                className={classNames(controls.buttonReset, modalClasses.modalNavBtn, modalClasses.navBtnNext)}
                onClick={() => loadNextFile(file)}
            >
                <FontAwesomeIcon icon={faArrowRight} />
            </button>
        </Dialog>
    </>;
};

import React from 'react';
import classNames from './Section.module.css';
import pageClassNames from '../styles/page.module.css';

type Props = {
    header?: React.ReactNode;
    children: React.ReactNode;
    expanded?: boolean;
};

export default function Section({ header, children, expanded = false }: Props) {
    const classes = [pageClassNames.centerContent, classNames.section];

    if (expanded) {
        classes.push(classNames.expanded);
    }

    return (
        <section className={classes.join(' ')}>
            <header className={`${pageClassNames.centerContent} ${classNames.header}`}>
                {header}
            </header>

            {children}
        </section>
    );
};

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import classNames from 'classnames';
import searchClasses from './Search.module.css';
import navbarClasses from './Navbar.module.css';

interface Props {
    search: (query: string) => void;
    searching: boolean,
}

export default function Search(props: Props) {
    const [query, setQuery] = useState('');

    return (
        <div className={searchClasses.search}>
            <input
                type='text'
                value={query}
                onChange={event => setQuery(event.target.value)}
                className={searchClasses.queryInput}
                placeholder='Search...' />

            <button
                onClick={() => props.search(query)}
                className={classNames(navbarClasses.navbarButton, searchClasses.searchButton)}
            >
                {props.searching ?
                    <FontAwesomeIcon icon={faSync} spin /> :
                    <FontAwesomeIcon icon={faSearch} />}
            </button>
        </div>
    );
}

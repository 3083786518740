import React from 'react';
import classNames from './ExternalLinks.module.css';
import SeparatedList from '../../common/components/SeparatedList';
import panelClasses from '../../common/styles/overview-panel.module.css';
import { TaxonNode } from '../../common/utils/api';

function getExternalLink(label: string, url: string, id: string | number | null) {
    if(!id) {
        return null;
    }

    const completeUrl = `${url}${id}`;

    return (
        <div key={id} className={classNames.inlineLink}>
            {label}: <a href={completeUrl} target="_blank" rel="noopener noreferrer">{id}</a>
        </div>
    );
}

export default function ExternalLinks(taxon: TaxonNode) {
    const hasLinks = taxon.attributes.col_id || taxon.attributes.mycobank_id;

    if(!hasLinks) {
        return null;
    }

    const colLink = getExternalLink(
        'Catalogue of Life',
        `https://www.catalogueoflife.org/data/taxon/`,
        taxon.attributes.col_id,
    );

    const mycobankLink = getExternalLink(
        'MycoBank',
        `https://www.mycobank.org/mb/`,
        taxon.attributes.mycobank_id,
    );

    const fungoriumLink = getExternalLink(
        'Index Fungorum',
        `https://indexfungorum.org/names/NamesRecord.asp?RecordID=`,
        taxon.attributes.mycobank_id,
    );

    return (
        <div className={classNames.externalLinks}>
            <label className={panelClasses.label}>
                Links to external resources
            </label>

            <SeparatedList separator='; '>
                {[colLink, mycobankLink, fungoriumLink].filter(link => link !== null)}
            </SeparatedList>
        </div>
    );
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import pageStyles from '../styles/page.module.css';
import classNames from './Navbar.module.css';

interface NavbarProps {
    title: string;
    icon: IconDefinition;
    children: React.ReactNode;
}

export default function Navbar(props: NavbarProps) {
    return <div className={`${pageStyles.centerContent} ${classNames.navbar}`}>
        <div className={classNames.navbarContent}>
            <div className={classNames.navbarTitleSection}>
                <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>

                <span className={classNames.navbarTitle}>
                    {props.title}
                </span>
            </div>

            <div className={classNames.navbarActionsSection}>
                {props.children}
            </div>
        </div>
    </div>
}

interface NavbarActionProps {
    onClick: () => void;
    label: string;
    tooltip?: string;
    icon: IconDefinition;
}

export function NavbarAction(props: NavbarActionProps) {
    return <button
        onClick={props.onClick}
        title={props.tooltip}
        className={classNames.navbarAction}
    >
        <FontAwesomeIcon icon={props.icon} />

        <span className={classNames.navbarActionLabel}>
            {props.label}
        </span>
    </button>
}
import React from 'react';
import classNames from '../styles/overview-panel.module.css';

type RowProps = {
    label: string;
    children: React.ReactNode;
};

export function Row(props: RowProps) {
    if (props.children) {
        return <tr>
            <td className={classNames.tableCell}>
                <label className={classNames.label}>
                    {props.label}
                </label>

                {props.children}
            </td>
        </tr>
    } else {
        return <></>;
    }
};

export function HorizontalRow(props: RowProps) {
    return (
        <tr>
            <td className={classNames.tableCell}>
                <label className={classNames.label}>
                    {props.label}
                </label>
            </td>

            <td className={classNames.tableCell}>
                {props.children}
            </td>
        </tr>
    );
};

type TablePanelProps = {
    header: string;
    children: React.ReactNode;
};

export function TablePanel({ header, children }: TablePanelProps) {
    return (
        <div className={classNames.panel}>
            <header className={classNames.header}>
                {header}
            </header>

            <table className={classNames.table}>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );
};

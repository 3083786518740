import React from 'react';
import controls from '../styles/controls.module.css';

type Props = {
    separator?: string;
    ender?: string;
    collapse?: boolean;
    collapsedCount?: number;
    children: React.ReactNode[];
};

type State = {
    collapsed: boolean;
};

// TODO: => functional component
export default class SeparatedList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { collapsed: this.props.collapse ?? true };
    }

    expand() {
        this.setState({ collapsed: false });
    }

    render() {
        const { separator = ', ', ender = '', collapsedCount = 10, children } = this.props;

        const elements = this.state.collapsed ?
            children.slice(0, collapsedCount) :
            children;

        // TODO: Add "show more"
        const list = elements.map((element, index) => (
            <span key={index.toString()}>
                {element}

                {index === (elements.length - 1) ? ender : separator}
            </span>
        ));

        if(elements.length < children.length) {
            list.push(<React.Fragment key='show-more'>
                <br />
                <button onClick={() => this.expand()} className={`${controls.buttonReset} ${controls.linkButton}`}>
                    Show more
                </button>
            </React.Fragment>);
        }

        return list;
    }
};

import React from 'react';
import Files from './Files';
import SeparatedList from './SeparatedList';
import { Panel, PanelField } from './Panel';
import classNames from './MetadataFull.module.css';
import { DOI } from '../utils/api';

function list(items: string[]) {
    return <ul className={classNames.list}>
        {items.map((item, index) => (
            <li className={classNames.listItem} key={index}>
                {item}
            </li>
        ))}
    </ul>
}

export default function MetadataFull({ metadata }: { metadata: DOI }) {
    return (
        <Panel>
            <PanelField label='Title'>
                {metadata.attributes.title}
            </PanelField>

            <PanelField label='Subtitle'>
                {metadata.attributes.subtitle}
            </PanelField>

            <PanelField label='Location'>
                <a href={metadata.attributes.doi}>
                    {metadata.attributes.doi}
                </a>
            </PanelField>

            <PanelField label='Publisher'>
                {metadata.attributes.publisher}
            </PanelField>

            <PanelField label='Published'>
                {metadata.attributes.publication_year}
            </PanelField>

            <PanelField label='Resource type'>
                {metadata.attributes.resource_type}
            </PanelField>

            <PanelField label='Creators'>
                {list(metadata.attributes.creators.map(({creator_name, affiliation}) => {
                    if(affiliation) {
                        return `${creator_name} (${affiliation})`;
                    } else {
                        return creator_name;
                    }
                }))}
            </PanelField>

            <PanelField label='Taxa'>
                <SeparatedList>
                    {metadata.attributes.taxa.map(taxon => taxon.full_taxon_name)}
                </SeparatedList>
            </PanelField>

            <PanelField label='Contributors'>
                {list(metadata.attributes.contributors.map(({ contributor_type: type, contributor_name: name }) => `${type}: ${name}`))}
            </PanelField>

            <PanelField label='Dates'>
                {list(metadata.attributes.dates.map(date => `${date.date_type}: ${date.date}`))}
            </PanelField>

            <PanelField label='Funders'>
                {list(metadata.attributes.funding_references.map(funder => {
                    return [
                        funder.funder_name, funder.funder_identifier, funder.identifier_type,
                        funder.award_number, funder.award_uri, funder.award_title,
                    ].filter(Boolean).join(', ');
                }))}
            </PanelField>

            <PanelField label='Abstract'>
                {metadata.attributes.abstract}
            </PanelField>

            <PanelField label='Formats'>
                {list(metadata.attributes.formats)}
            </PanelField>

            <PanelField label='Subjects/keywords'>
                <SeparatedList>
                    {metadata.attributes.subjects}
                </SeparatedList>
            </PanelField>

            <PanelField label='Licence'>
                <a href={metadata.attributes.licence.rights_uri} target="_blank" rel="noopener noreferrer">
                    {metadata.attributes.licence.rights}
                </a>
            </PanelField>

            <PanelField label='Citation'>
                {metadata.attributes.citation}
            </PanelField>

            <PanelField label='Related identifiers'>
                {list(metadata.attributes.related_identifiers.map(rid => {
                    return `${rid.relation_type}:  ${rid.related_identifier} (${rid.resource_type_general})`
                }))}
            </PanelField>

            <PanelField label='Alternate identifiers'>
                {list(metadata.attributes.alternate_identifiers.map(aid => `${aid.alternate_identifier} (${aid.alternate_identifier_type})`))}
            </PanelField>

            {metadata.attributes.media.length && <PanelField label='Downloads'>
                <Files metadata={metadata} />
            </PanelField>}
        </Panel>
    );
};

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import classes from './ClipboardButton.module.css';
import controlClasses from '../styles/controls.module.css';

export function ClipboardButton({ value }: { value: string }) {
    const [copied, setCopied] = useState(false);

    function copy() {
        window.navigator.clipboard.writeText(value).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        })
    }

    return <div className={classes.clipboard}>
        {copied ?
            <span className={classes.feedback}>
                <FontAwesomeIcon icon={faCheck} />
                Copied
            </span> :

            <button
                className={`${controlClasses.buttonReset} ${classes.button}`}
                onClick={copy}
            >
                Copy
            </button>}
    </div>;
}
import axios from 'axios';
import { z } from 'zod';
import { loadAttachedJSON, loadMetadata } from '../../common/utils/api';
import { Config } from '../../common/utils/config';

const taxonSchema = z.object({
    id: z.number(),
    rank: z.string(),
    taxon_name: z.string(),
    doi: z.string(),
    th_code: z.string(),
});

const thSchema = z.object({
    id: z.number(),
    th_code: z.string(),
    updated_at: z.string(),
    doi: z.string(),

    taxon: z.object({
        id: z.number(),
        full_taxon_name: z.string(),
        taxon_name: z.string(),
        lsid: z.string().optional(),  // TODO - removed in newer TH versions
        rank: z.string(),
        is_informal: z.boolean().optional(),  // TODO - removed in newer TH versions
        reference: z.string().optional(),  // TODO - removed in newer TH versions

        higher_classification: z.array(taxonSchema),
        lower_classification: z.array(taxonSchema),
    }),

    dshcluster_count: z.number(),
    its_sequence_count: z.number(),

    // TODO: Need to see one in the wild
    ecm_lineages: z.array(z.object({
        ecm_lineage: z.string(),
        count: z.number(),
    })),

    interacting_taxa: z.array(z.object({
        id: z.number(),
        taxon_name: z.string(),
        rank: z.string(),
        count: z.number(),
    })),

    distribution: z.array(z.object({
        country: z.string(),
        country_code: z.string(),
        lat: z.coerce.number().optional(),
        lng: z.coerce.number().optional(),
        country_lat: z.coerce.number().optional(),
        country_lng: z.coerce.number().optional(),
        count: z.number(),
    })),
});

export type TaxonHypothesis = z.infer<typeof thSchema>;
export type TaxonHypothesisTaxon = z.infer<typeof taxonSchema>;

const responseSchema = z.object({ taxon_hypotheses: thSchema });

export async function loadTaxonHypothesis(config: Config, metadataID: string): Promise<TaxonHypothesis> {
    const metadata = await loadMetadata(config, metadataID);
    const response = await loadAttachedJSON(metadata, responseSchema);

    return response.taxon_hypotheses;
};

const mappingResponseSchema = z.array(z.object({
    id: z.number(),
    thobject_code: z.string(),
    dshcluster_code: z.string(),
    dshcluster_doi: z.string(),
    dshcluster_sequences_count: z.number(),
}));

export type THLinkedSH = {
    code: string;
    doi: string;
    count: number;
};

// Example: 10.15156/BIO/TH074105
export async function* loadTHLinkedSHs(config: Config, thID: number, { pageSize = 20 } = {}): AsyncIterator<THLinkedSH> {
    async function loadPage(number: number) {
        const params = {
            thobject: thID,
            threshold: 5, // Don't even know which one it is (probably 1.5%), but the task lists it by id
            ordering: 'id',
            page_size: pageSize,
        };

        const rawResponse = await axios.get(`${config.external.plutof.api}/globalkey/thshmappings/`, { params });
        const response = mappingResponseSchema.parse(rawResponse.data);

        return response.map(mapping => ({
            code: mapping.dshcluster_code,
            doi: mapping.dshcluster_doi,
            count: mapping.dshcluster_sequences_count,
        }))
    }

    let haveMore = true;

    while (haveMore) {
        const page = await loadPage(1);

        if (page.length < pageSize) {
            haveMore = false;
        }

        for (const mapping of page) {
            yield mapping;
        }
    }
}

// TODO: Replace axios with fetch
import axios from 'axios';
import { z } from 'zod';
import { Config } from './config';

export function publicAPIRecord<T extends z.ZodTypeAny>(recordSchema: T) {
    return z.object({
        data: recordSchema,
    });
}

export function publicAPIList<T extends z.ZodTypeAny>(itemSchema: T) {
    return z.object({
        data: z.array(itemSchema),
        meta: z.object({
            pagination: z.object({
                page: z.number(),
                pages: z.number(),
                count: z.number(),
            }),
        })
    });
}

export interface PublicAPIList<T> {
    data: T[];

    meta: {
        pagination: {
            page: number;
            pages: number;
            count: number;
        },
    };
}

// TODO?: Here's an idea for you - a separate package with zod parsers and types for the
// PlutoF public API that clients can use to make API calls safer
const doiCreatorSchema = z.object({
    creator_name: z.string(),
    name_type: z.string(), // TODO: This is probably an enum
    affiliation: z.string(),
    given_name: z.string().optional(),
    family_name: z.string().optional(),
});

const doiMediaSchema = z.object({
    content_type: z.string(),
    doi: z.string(),
    name: z.string(),
    url: z.string(),
});

const doiSchema = z.object({
    type: z.literal('works'),
    id: z.string(),
    attributes: z.object({
        doi: z.string(),
        owner_name: z.string(),
        publisher: z.string(),
        publication_year: z.string(),
        resource_type: z.string(),
        language: z.string().nullable(),
        title: z.string(),
        subtitle: z.string(),
        abstract: z.string(),
        identifier: z.string(),
        citation: z.string(),
        creators: z.array(doiCreatorSchema),
        subjects: z.array(z.string()), // TODO: Don't know what's here

        contributors: z.array(z.discriminatedUnion('name_type', [
            z.object({
                name_type: z.literal('Personal'),
                contributor_type: z.string(), // TODO: Enum
                contributor_name: z.string(),
                affiliation: z.string(),
                given_name: z.string(),
                family_name: z.string(),
            }),

            z.object({
                name_type: z.literal('Organizational'),
                contributor_type: z.string(), // TODO: Enum
                contributor_name: z.string(),
                affiliation: z.string(),
            }),
        ])),

        dates: z.array(z.object({
            date_type: z.string(), // TODO: Enum
            date_information: z.string(),
            date: z.string(),
        })),

        alternate_identifiers: z.array(z.object({
            alternate_identifier: z.string(),
            alternate_identifier_type: z.string(),
        })),

        related_identifiers: z.array(z.object({
            relation_type: z.string(),
            related_identifier: z.string(),
            resource_type_general: z.string(),
        })),

        sizes: z.array(z.unknown()),
        formats: z.array(z.string()),

        licence: z.object({
            rights: z.string(),
            rights_uri: z.string(),
        }),

        geo_locations: z.array(z.object({
            geo_location_place: z.string(),
            wkt: z.string(),
            centroid_wkt: z.string(),
            country_centroid_wkt: z.string(),
            country_code: z.string(),
        })),

        funding_references: z.array(z.object({
            funder_name: z.string(),
            funder_identifier: z.string(),
            identifier_type: z.string(),
            award_number: z.string(),
            award_uri: z.string(),
            award_title: z.string(),
        })),

        media: z.array(doiMediaSchema),

        taxa: z.array(z.object({
            id: z.number(),
            full_taxon_name: z.string(),
        })),
    }),
});

const taxonNodeSchema = z.object({
    id: z.string(),
    attributes: z.object({
        col_id: z.string().nullable(),
        mycobank_id: z.number().nullable(),
    }),
});

export type DOI = z.infer<typeof doiSchema>;
export type DOIMedia = z.infer<typeof doiMediaSchema>;
export type TaxonNode = z.infer<typeof taxonNodeSchema>;

const searchDOIsSchema = publicAPIList(doiSchema);
const doiResponseSchema = publicAPIRecord(doiSchema);
const taxonResponseSchema = publicAPIRecord(taxonNodeSchema);

export async function searchDOIs(
    config: Config,
    query: string, { page = 1, pageSize = 20, ordering = '-updated_at' } = {}
): Promise<PublicAPIList<DOI>> {
    const params = {
        identifier: query,
        'page[number]': page,
        'page[size]': pageSize,
        ordering,
    };

    const response = await axios.get(`${config.external.plutof.api}/public/dois/`, { params });

    return searchDOIsSchema.parse(response.data);
};

export async function loadMetadata(config: Config, id: string, { registered = true } = {}): Promise<DOI> {
    const params = {
        registered: registered ? 'True' : 'False',
        include: 'taxa',
    };

    const response = await axios.get(`${config.external.plutof.api}/public/dois/${id}/`, { params });

    const parsedResponse = doiResponseSchema.parse(response.data);

    return parsedResponse.data;
}

export async function loadTaxonNode(config: Config, id: string): Promise<TaxonNode> {
    const response = await axios.get(`${config.external.plutof.api}/public/taxa/${id}/`);
    const parsedResponse = taxonResponseSchema.parse(response.data);

    return parsedResponse.data;
}

export async function loadMetadataWithIdentifier(config: Config, identifier: string): Promise<DOI> {
    const params = { identifier };
    const response = await axios.get(`${config.external.plutof.api}/public/dois/`, { params });

    const parsedResponse = searchDOIsSchema.parse(response.data);

    if (parsedResponse.data.length === 1) {
        return parsedResponse.data[0];
    }

    if (parsedResponse.data.length === 2) {
        throw new Error(`DOI ${identifier} couldn't be uniquely identified`);
    }

    throw new Error(`DOI ${identifier} not found`);
}

export function logDownload(
    config: Config,
    doi: string,
    filename: string,
    fields: {
        name: string,
        email: string,
        organization: string,
        reason: string,
    },
) {
    return axios.post(`${config.external.plutof.api}/globalkey/log-download/`, {
        doi,
        filename,
        ...fields,
    });
}

export function urlToObjectID(url: string) {
    const parts = url.split('/');

    return parts[parts.length - 2];
}

export function getDOICount(config: Config, query: string): Promise<number> {
    let params: Record<string, any> = {};

    if(query) {
        params.identifier = query;
    }

    return axios.get(`${config.external.plutof.api}/public/dois/count/`, { params })
        .then(response => response.data.data.objects_count);
}

export async function loadAttachedJSON<T>(doi: DOI, schema: z.ZodType<T>): Promise<T> {
    const jsons = doi.attributes.media.filter(file => file.content_type === 'application/json');

    if (jsons.length === 0) {
        throw new Error(`DOI ${doi.id} does not have a JSON attached`);
    }

    const file = jsons[jsons.length - 1];

    const rawResponse = await axios.get(file.url, {
        headers: {
            'Cache-Control': 'max-age=0',
        },
    });

    const response = schema.parse(rawResponse.data);

    return response;
}

import React, { useEffect, useRef } from 'react';
import { Chart, ChartConfiguration, registerables } from 'chart.js';

Chart.register(...registerables);

function bucketColors(count: number, { hueStart = 0, hueEnd = 360, saturation = 100, luminosity = 50 } = {}) {
    const hueStep = (hueEnd - hueStart) / count;
    const colors = [];

    for(let i = 0, hue = hueStart; i < count; i++, hue += hueStep) {
        colors.push(`hsl(${hue}, ${saturation}%, ${luminosity}%)`);
    }

    return colors;
}

function pieChart(data: ChartData, title?: string): ChartConfiguration {
    const chartData = {
        labels: data.map(point => point.label),

        datasets: [{
            data: data.map(point => point.count),
            backgroundColor: bucketColors(data.length, { saturation: 40 }),
            hoverOffset: 4,
        }],
    };

    return {
        type: 'doughnut',
        data: chartData,
        options: {
            responsive: true, 
            aspectRatio: 2.5,

            plugins: {
                title: {
                    display: Boolean(title),
                    text: title,
                },

                legend: {
                    display: false,
                    position: 'right',
                }
            }
        },
    }
}

type ChartData = { label: string, count: number }[];

interface Props {
    title?: string;
    data: ChartData;
}

export default function PieChart({ title, data }: Props) {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const canvasContext = canvas?.getContext('2d');

        if (canvasContext) {
            const config = pieChart(data, title);
            const chart = new Chart(canvasContext, config);

            return () => {
                chart.destroy();
            };
        }
    }, [title, data]);

    return <canvas ref={canvasRef}></canvas>
}

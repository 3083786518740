import React from 'react';
import classnames from 'classnames';
import navbarClassNames from './Navbar.module.css';
import pageClassNames from '../common/styles/page.module.css';

interface Props {
    search: React.ReactNode;
    pagination: React.ReactNode;
}

export default function Navbar({ search, pagination }: Props) {
    return (
        <nav className={classnames(pageClassNames.centerContent, navbarClassNames.navbar)}>
            <div className={navbarClassNames.search}>
                {search}
            </div>

            <div className={navbarClassNames.pagination}>
                {pagination}
            </div>
        </nav>
    );
}

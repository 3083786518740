import React from 'react';
import classNames from './SequenceStatus.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTint } from '@fortawesome/free-solid-svg-icons/faTint';
import { SEQUENCE_STATUSES } from '../utils/sequence-status';

export default function SequenceStatus({ status }: { status: typeof SEQUENCE_STATUSES[0] }) {
    return (
        <div className={classNames.status}>
            <FontAwesomeIcon icon={faTint} color={status.color} />
        </div>
    );
};

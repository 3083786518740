import React from 'react';
import { SEQUENCE_STATUSES } from '../utils/sequence-status';
import SequenceStatus from './SequenceStatus';

export default function StatusLegend() {
    return (
        <div>
            {SEQUENCE_STATUSES.map(status => (
                <div key={status.field}>
                    <SequenceStatus status={status} />

                    {status.name}
                </div>
            ))}
        </div>
    );
};

import React from 'react';
import { PanelContent, PanelField, PanelSection } from '../../common/components/Panel';
import BlankLink from '../../common/components/BlankLink';
import classNames from './Statistics.module.css';

interface Props {
    distance: string;
    sequenceCount: number;
    graph: string | null;
}

export default function Statistics({ distance, sequenceCount, graph }: Props) {
    return (
        <>
            <PanelContent>
                <PanelField label='Minimum distance to the closest SH'>
                    {distance}
                </PanelField>

                <PanelField label='No. of individuals in SH'>
                    {sequenceCount}
                </PanelField>
            </PanelContent>

            {graph && <PanelContent>
                {graph && <PanelSection>
                    <BlankLink href={graph}>
                        <img src={graph} className={classNames.graph} alt="SH distribution graph" />
                    </BlankLink>
                </PanelSection>}
            </PanelContent>}
        </>
    );
};

import React from 'react';

type BlankLinkProps = {
    href: string,
    children: React.ReactNode;
}

export default function BlankLink({ href, children }: BlankLinkProps) {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    )
}

import React from 'react';
import Section from '../common/components/Section';
import DetailsSection from '../common/components/DetailsSection';
import classNames from '../common/styles/page.module.css';
import { DOI } from '../common/utils/api';

export default function GeneralDOIPage({ metadata }: { metadata: DOI }) {
    return (
        <div className={classNames.page}>
            <Section header={`DOI: ${metadata.attributes.identifier}`}>
                <DetailsSection
                    metadata={metadata} />
            </Section>
        </div>
    );
};

import React, { useState } from 'react';
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons/faSortAmountDown';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard';
import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons/faCompressArrowsAlt';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons/faExpandArrowsAlt';
import { ClipboardButton } from '../../common/components/ClipboardButton';
import DistributionMap from '../../common/components/DistributionMap';
import Section from '../../common/components/Section';
import { TabGroup } from '../../common/components/TabGroup';
import RepresentativeSequence from './RepresentativeSequence';
import Taxonomy from './Taxonomy';
import Statistics from './Statistics';
import GraphicalView from './GraphicalView';
import Gallery from './Gallery';
import classNames from './SHPage.module.css';

import hypothesisPageClassNames from '../../common/components/HypothesisPage.module.css';
import Header from '../../common/components/Header';
import Distribution from '../../common/components/Distribution';
import DetailsSection from '../../common/components/DetailsSection';
import { Panel, PanelContent, PanelField } from '../../common/components/Panel';
import SeparatedList from '../../common/components/SeparatedList';
import PreviousSHVersions from './PreviousVersions';
import Files from '../../common/components/Files';

import PieChart from '../../common/components/charts/PieChart';
import { DOI, TaxonNode } from '../../common/utils/api';
import { SHCluster, SHData, SHDataSequenceImage } from '../utils/api';
import Navbar, { NavbarAction } from '../../common/components/Navbar';

function getGraph(metadata: DOI, speciesHypothesis: SHData) {
    if(!speciesHypothesis.species_hypotheses.distribution_graph) {
        return null;
    }

    const fileName = speciesHypothesis.species_hypotheses.distribution_graph;
    const graphName = fileName.slice(0, fileName.lastIndexOf('.'));

    const file = metadata.attributes.media.filter(file => file.name === graphName)[0];

    return file ? file.url : null;
}

function extension(filename: string) {
    const index = filename.lastIndexOf('.');

    return index === -1 ? '' : filename.slice(index + 1);
}

// Some download links in SH JSON files are missing extensions for some reason
// Instead of regenerating them and potentially screwing stuff up, because nobody
// can say if they are correct, we hack around it
function hackMissingDownloadLinkExtension(links: Record<string, string | null>) {
    const extensions = new Set();
    const missing = [];

    for(const key of Object.keys(links)) {
        const link = links[key];

        if(link) {
            const filename = link.slice(link.lastIndexOf('/') + 1);
            const ext = extension(filename);

            if(ext) {
                extensions.add(ext);
            } else {
                missing.push(key);
            }
        }
    }

    if(missing.length === 0 || extensions.size === 0) {
        return;
    }

    // If there are >1 extensions, throw a dart at them
    const { value: newExtension } = extensions.keys().next();

    for(const key of missing) {
        links[key] = `${links[key]}.${newExtension}`;
    }
}

function getImages(sh: SHData) {
    let images: SHDataSequenceImage[] = [];

    sh.species_hypotheses.sequences.forEach(sequence => {
        if(sequence.images) {
            sequence.images.forEach(image => images.push(image));
        }
    });

    for(const image of images) {
        hackMissingDownloadLinkExtension(image.download_links);
    }

    return images;
}

function Communication({ metadata, sh }: { metadata: DOI, sh: SHData }) {
    return <Panel header='Communication of the SH'>
        <PanelField label='Digital Object Identifier (DOI)'>
            <a href={metadata.attributes.doi}>
                {metadata.attributes.doi}
            </a>
        </PanelField>

        <PanelField label='Taxon name'>
            {sh.species_hypotheses.taxon.taxon_name}
        </PanelField>
    </Panel>
}

function NewMetadata({ metadata }: { metadata: DOI }) {
    return <Panel header='DOI metadata'>
        <PanelField label='Title'>
            {metadata.attributes.title}
        </PanelField>

        <PanelField label='Publisher'>
            {metadata.attributes.publisher}
        </PanelField>

        <PanelField label='Published'>
            {metadata.attributes.publication_year}
        </PanelField>

        <PanelField
            label={
                <React.Fragment>
                    Citation

                    <ClipboardButton value={metadata.attributes.citation} />

                </React.Fragment>
            }
        >
            {metadata.attributes.citation}
        </PanelField>

        {metadata.attributes.media.length && <PanelField label='Downloads'>
            <Files metadata={metadata} />
        </PanelField>}
    </Panel>
}

interface Props {
    metadata: DOI;
    cluster: SHCluster;
    shData: SHData;
    taxonNode: TaxonNode | null;
}

export default function SHPage({ metadata, cluster, shData, taxonNode }: Props) {
    const [alignmentExpanded, toggleAlignmentExpanded] = useState(false);

    let graph = getGraph(metadata, shData);
    let images = getImages(shData);

    const previousVersions = cluster.attributes.previous_versions;
    const synonyms = cluster.attributes.taxonomy.synonyms;

    const map = <DistributionMap distribution={shData.distribution} />;

    // TODO: Theree was a is_reference_sequence thing, deal with it
    const refseq = shData.species_hypotheses.reference_sequence;

    let representativeSequence;

    if(refseq) {
        representativeSequence = <RepresentativeSequence
            sequence={refseq}
            header='Reference sequence' />
    } else if(shData.species_hypotheses.representative_sequence) {
        representativeSequence = <RepresentativeSequence
            sequence={shData.species_hypotheses.representative_sequence}
            header='Centroid sequence' />
    }

    const tabs = [
        {
            name: 'Ecology',
            content: <>
                <PanelContent>
                    <PanelField label='EcM lineage'>
                        <SeparatedList separator='; '>
                            {(shData.species_hypotheses.ecm_lineages ?? []).map(ecm => `${ecm.ecm_lineage} (${ecm.count})`)}
                        </SeparatedList>
                    </PanelField>

                    <PanelField label='Interacting taxa'>
                        <SeparatedList separator='; '>
                            {(shData.species_hypotheses.interacting_taxa ?? []).map(interaction => `${interaction.taxon_name} (${interaction.count})`)}
                        </SeparatedList>
                    </PanelField>
                </PanelContent>

                <div style={{width: '100%'}}>
                    <PieChart data={(shData.species_hypotheses.interacting_taxa ?? []).map(it => ({
                        label: it.taxon_name,
                        count: it.count,
                    }))} />
                </div>
            </>
        },

        {
            name: 'Taxonomy',
            content: <>
                <PanelContent>
                    {synonyms.length > 0 && <PanelField label='Synonyms'>
                        <SeparatedList separator='; '>
                            {synonyms.map(synonym => synonym.name)}
                        </SeparatedList>
                    </PanelField>}

                    <PanelField label='Identifications'>
                        <SeparatedList separator='; '>
                            {shData.species_hypotheses.identifications.map(identification => `${identification.taxon_name} (${identification.count})`)}
                        </SeparatedList>
                    </PanelField>
                </PanelContent>

                <div style={{width: '100%'}}>
                    <PieChart data={shData.species_hypotheses.identifications.map(ident => ({
                        label: ident.taxon_name,
                        count: ident.count,
                    }))} />
                </div>
            </>
        },

        {
            name: 'Statistics',
            content: <Statistics
                distance={shData.species_hypotheses.distance}
                sequenceCount={shData.species_hypotheses.sequence_count}
                graph={graph} />
        },
    ];

    if (previousVersions.length > 0) {
        tabs.push({
            name: 'History',
            content: <PreviousSHVersions versions={previousVersions} />
        })
    }

    // TODO: Ecology
    return (
        <div className={classNames.page}>
            <Header
                name={`${shData.species_hypotheses.taxon.taxon_name} | ${shData.species_hypotheses.sh_code}`}
                source='Species Hypotheses pages' />

            <Navbar title='Species Hypotheses (SH)' icon={faSortAmountDown}>
                <NavbarAction
                    onClick={() => toggleAlignmentExpanded(!alignmentExpanded)}
                    label='Widescreen'
                    tooltip={'Toggle alignment panel\'s width'}
                    icon={alignmentExpanded ? faCompressArrowsAlt : faExpandArrowsAlt} />

                <NavbarAction
                    onClick={() => downloadFASTA(shData)}
                    label='FASTA'
                    icon={faDownload} />

                <NavbarAction
                    onClick={() => {}}
                    label='Curate'
                    icon={faClipboard} />
            </Navbar>

            <Section>
                <div className={hypothesisPageClassNames.overview}>
                    <div className={hypothesisPageClassNames.overviewColumn}>
                        <Communication
                            metadata={metadata}
                            sh={shData} />

                        <Taxonomy
                            lineage={cluster.attributes.taxonomy.lineage}
                            taxonNode={taxonNode} />

                        {representativeSequence}

                        <TabGroup tabs={tabs} />
                    </div>

                    <div className={hypothesisPageClassNames.overviewColumn}>
                        <div className={classNames.rightColumn}>
                            <div className={classNames.map}>
                                <Distribution map={map} />
                            </div>
                        </div>

                        {images && images.length > 0 && (
                            <Gallery files={images} />
                        )}

                        <NewMetadata metadata={metadata} />
                    </div>
                </div>
            </Section>

            <Section
                header={`Individuals of the current SH (total: ${cluster.attributes.sequence_count}, core: ${cluster.attributes.sequence_count_core})`}
                expanded={alignmentExpanded}
            >
                <GraphicalView
                    sequences={shData.species_hypotheses.sequences}
                    shCode={shData.species_hypotheses.sh_code}
                    toggleWidth={toggleAlignmentExpanded}
                    isWide={alignmentExpanded} />
            </Section>

            <Section header='Detailed information'>
                <DetailsSection metadata={metadata} />
            </Section>
        </div>
    );
}

function downloadFASTA(sh: SHData) {
    const content = sh.species_hypotheses.sequences
        .map(seq => {
            const identifier = seq.accnos.map(accno => accno.accno);
            
            return `>${identifier}\n${seq.its_sequence}`;
        })
        .join('\n');

    const blob = new Blob([content]);

    const reader = new FileReader();

    reader.onloadend = () => {
        const result = reader.result as string;

        const link = document.createElement('a');
        link.href = result;
        link.download = `${sh.species_hypotheses.sh_code}.fsa`;

        link.click();
    };

    reader.readAsDataURL(blob);

}
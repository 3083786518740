import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import panelClasses from '../../common/styles/overview-panel.module.css';
import { Row } from '../../common/components/OverviewPanel';
import SeparatedList from '../../common/components/SeparatedList';
import { TaxonHypothesis, TaxonHypothesisTaxon, loadTHLinkedSHs } from '../utils/api';
import { TaxonNode } from '../../common/utils/api';
import { ConfigContext } from '../../common/utils/config';
import { LinkButton } from '../../common/components/controls/buttons';
import ExternalLinks from '../../common/components/ExternalLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function classification(taxa: TaxonHypothesisTaxon[]) {
    // Because of the bug in TH generation, some taxa are duplicated
    // in taxonomy: [Fungi, Fungi, Dikarya, Dikarya, ...]
    // TODO: Remove this when TH's are regenerated properly
    const uniqueTaxa: TaxonHypothesisTaxon[] = [];
    let previous = null;

    for(const taxon of taxa) {
        if(taxon.id !== previous) {
            uniqueTaxa.push(taxon);
            previous = taxon.id;
        }
    }

    return <SeparatedList separator='; '>
        {uniqueTaxa.map(taxon => (
            <span key={taxon.taxon_name}>
                {taxon.taxon_name}&nbsp;

                (<Link to={`/doi/${taxon.doi}`}>
                    {taxon.th_code}
                </Link>)
            </span>
        ))}
    </SeparatedList>;
}

function useAsyncIterator<T>(generator: AsyncIterator<T>) {
    const [loaded, setLoaded] = useState<T[]>([]);
    const [haveMore, setHaveMore] = useState(true);
    const [loading, setLoading] = useState(false);

    const loadMore = useCallback(async function(count: number) {
        setLoading(true);

        try {
            let page: T[] = [];

            for(let i = 0; i < count; i++) {
                const item = await generator.next();

                if (item.done) {
                    setHaveMore(false);
                    break;
                }

                page.push(item.value);
            }

            setLoaded(shown => shown.concat(page));
        } finally {
            setLoading(false);
        }

    }, [generator]);

    // XXX TODO: This loads two times, and I don't know why
    useEffect(() => {
        loadMore(5);
    }, [loadMore]);

    return { loaded, haveMore, loadMore, loading };
}

interface Props {
    taxonHypothesis: TaxonHypothesis,
    taxonNode: TaxonNode,
}

export default function Taxonomy(props: Props) {
    const lineage = classification(props.taxonHypothesis.taxon.higher_classification);

    // TODO: Check on some informal taxon
    const informal = props.taxonHypothesis.taxon.is_informal && props.taxonHypothesis.taxon.reference;

    const children = classification(props.taxonHypothesis.taxon.lower_classification);

    const config = useContext(ConfigContext);
    const linkedSHIterator = useMemo(() => loadTHLinkedSHs(config, props.taxonHypothesis.id), [config, props.taxonHypothesis.id]);
    const linkedSH = useAsyncIterator(linkedSHIterator);

    return (
        <div className={panelClasses.panel}>
            <header className={panelClasses.header}>
                Taxonomy
            </header>

            <table className={panelClasses.table}>
                <tbody>
                    <Row label='Placement in taxonomy'>
                        {lineage}
                        {props.taxonNode ? ExternalLinks(props.taxonNode) : '' }
                    </Row>

                    <Row label='Informal name'>
                        {informal}
                    </Row>

                    <Row label='Children'>
                        {children}
                    </Row>

                    <Row label='Species hypotheses (1.5%)'>
                        <SeparatedList collapse={false}>
                            {linkedSH.loaded.map(sh => <Link key={sh.code} to={`/doi/${sh.doi}`}>
                                {sh.code} ({sh.count})
                            </Link>)}
                        </SeparatedList>

                        <br />

                        {
                            linkedSH.loading ?
                                <FontAwesomeIcon icon={faSpinner} spin /> :
                                linkedSH.haveMore && <LinkButton
                                    onClick={() => linkedSH.loadMore(10)}
                                >
                                    Show more
                                </LinkButton>
                        }
                    </Row>
                </tbody>
            </table>
        </div>
    );
};

import React from 'react';
import { ClipboardButton } from './ClipboardButton';
import { Panel, PanelField } from './Panel';
import SeparatedList from './SeparatedList';
import { DOI } from '../utils/api';

export default function Metadata({ metadata }: { metadata: DOI }) {
    return (
        <Panel header='Metadata'>
            <PanelField label='Location'>
                <a href={metadata.attributes.doi}>
                    {metadata.attributes.identifier}
                </a>
            </PanelField>

            <PanelField
                label={
                    <React.Fragment>
                        Citation

                        <ClipboardButton value={metadata.attributes.citation} />

                    </React.Fragment>
                }
            >
                {metadata.attributes.citation}
            </PanelField>

            <PanelField label="Publisher">
                {metadata.attributes.publisher}
            </PanelField>

            <PanelField label="Published">
                {metadata.attributes.publication_year}
            </PanelField>

            <PanelField label="Creators">
                <SeparatedList separator='; ' ender='.'>
                    {metadata.attributes.creators.map(({creator_name, affiliation}) => {
                        if(affiliation) {
                            return `${creator_name} (${affiliation})`;
                        } else {
                            return creator_name;
                        }
                    })}
                </SeparatedList>
            </PanelField>

            <PanelField label="Documents">
                <SeparatedList separator='; '>
                    {metadata.attributes.media.map(file => file.name)}
                </SeparatedList>
            </PanelField>
        </Panel>
    );
}

import React from 'react';
import classNames from './fields.module.css';

export function FormField({ label, children }: { label: string, children: React.ReactNode }) {
    return <div>
        <label>{label}</label>
        {children}
    </div>
}

function inputClassName(invalid: boolean) {
    let className = classNames.textInput;

    if(invalid) {
        className += ' ' + classNames.textInput_invalid;
    }

    return className;
}

type TextInputProps = {
    type?: string,
    value: string,
    onChange: (value: string) => void;
    invalid: boolean;
    autoComplete?: string;
}

export function TextInput({ type = 'text', value, onChange, invalid = false, autoComplete }: TextInputProps) {
    return <input
        type={type}
        value={value}
        onChange={event => onChange(event.target.value)}
        className={inputClassName(invalid)}
        autoComplete={autoComplete}
    >
    </input>
}

type TextAreaProps = {
    value: string,
    onChange: (value: string) => void;
    invalid: boolean;
}

export function TextArea({ value, onChange, invalid }: TextAreaProps) {
    return <textarea
        value={value}
        onChange={event => onChange(event.target.value)}
        className={inputClassName(invalid)} />
}

type DropdownProps = {
    value: string,
    onChange: (value: string) => void;
    invalid: boolean;
    children: React.ReactNode;
    className?: string;
}

export function Dropdown({ value, onChange, invalid = false, children, className = '' }: DropdownProps) {
    const fullClassName = invalid ?
        `${classNames.dropdown} ${classNames.dropdown_invalid} ${className}` :
        `${classNames.dropdown} ${className}`;

    return <select value={value} onChange={event => onChange(event.target.value)} className={fullClassName}>
        {children}
    </select>;
}
import React from 'react';
import classNames from './Modal.module.css';

type ModalProps = {
    close: () => void;
    children: React.ReactNode;
};

export function Modal(props: ModalProps) {
    return (
        <div className={classNames.container}>
            <div
                className={classNames.background}
                onClick={props.close} />

            <div className={classNames.content}>
                {props.children}
            </div>
        </div>
    )
}

type DialogProps = {
    header: React.ReactNode;
    children: React.ReactNode;
    footer: React.ReactNode;
};

export function Dialog(props: DialogProps) {
    return (
        <div className={classNames.dialog}>
            <div className={classNames.dialogHeader}>
                {props.header}
            </div>

            <div className={classNames.dialogBody}>
                {props.children}
            </div>

            <div className={classNames.dialogFooter}>
                {props.footer}
            </div>
        </div>
    )
}
import React from 'react';

export function buildConfig({
    plutofAPI = 'https://api.plutof.ut.ee/v1',
    plutofApp = 'https://app.plutof.ut.ee',
    plutofLandingPage = 'https://plutof.ut.ee',
    rollbarEnvironment = process.env.NODE_ENV,
} = {}) {
    return {
        external: {
            plutof: {
                api: plutofAPI,
                app: plutofApp,
                landing: plutofLandingPage,

                views: {
                    sequence: (id: number) => `${plutofApp}/sequence/view/${id}`,
                },
            },

            mapbox: {},

            rollbar: {
                enabled: rollbarEnvironment === 'production',
                environment: rollbarEnvironment,
                token: 'd23142609bfc43bc8b1a71a39f38ee82',
            },
        },
    };
};

export type Config = ReturnType<typeof buildConfig>;

export const ConfigContext = React.createContext<Config>(buildConfig());

import React from 'react';
import DistributionMap from '../../common/components/DistributionMap';
import HypothesisPage from '../../common/components/HypothesisPage';
import Taxonomy from './Taxonomy';
import Statistics from './Statistics';
import Ecology from './Ecology';
import { DOI, TaxonNode } from '../../common/utils/api';
import { TaxonHypothesis } from '../utils/api';

interface Props {
    metadata: DOI,
    taxonHypothesis: TaxonHypothesis,
    taxonNode: TaxonNode,
}

export default function THPage({ metadata, taxonHypothesis: th, taxonNode }: Props) {

    return (
        <HypothesisPage
            metadata={metadata}
            header={`${th.taxon.taxon_name || th.taxon.full_taxon_name} | ${th.th_code}`}
            headerSource='Taxon Hypothesis pages'

            columnLeft={<React.Fragment>
                <Taxonomy
                    taxonHypothesis={th}
                    taxonNode={taxonNode} />

                <Statistics
                    clusters={th.dshcluster_count}
                    itsSequences={th.its_sequence_count} />

                <Ecology
                    ecmLineages={th.ecm_lineages}
                    interactingTaxa={th.interacting_taxa} />
            </React.Fragment>}

            map={
                <DistributionMap distribution={th.distribution} />
            }
        />
    );
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider, ErrorBoundary } from '@rollbar/react';

import { buildConfig, ConfigContext } from './common/utils/config';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const config = buildConfig({
    plutofAPI: process.env.REACT_APP_CONFIG_PLUTOF_API,
    plutofApp: process.env.REACT_APP_CONFIG_PLUTOF_FRONTEND,
    plutofLandingPage: process.env.REACT_APP_CONFIG_PLUTOF_LANDING_PAGE,
});

let app = <ConfigContext.Provider value={config}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
</ConfigContext.Provider>;

if(config.external.rollbar.enabled) {
    const rollbarConfig = {
        accessToken: config.external.rollbar.token,
        environment: config.external.rollbar.environment,
    };

    app = <Provider config={rollbarConfig}>
        <ErrorBoundary>
            {app}
        </ErrorBoundary>
    </Provider>
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        {app}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
